/**
 * Converts an object to a URL query string format.
 * @param params - An object containing key-value pairs to be converted.
 * @returns A string in query string format.
 */
export function toQueryString(params: Record<string, any>): string {
  // Join all key-value pairs with '&'
  return Object.keys(params)
    .map(key => {
      const value = params[key];

      // Skip null or undefined values
      if (value === null || value === undefined) {
        return '';
      }

      // If value is an array, create key=value pairs for each item
      if (Array.isArray(value)) {
        return value
          .map((item: any) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
          .join('&');
      }

      // If value is an object, stringify it as JSON
      if (typeof value === 'object') {
        return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
      }

      // For other types (string, number, boolean), encode the key and value
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .filter(part => part.length > 0) // Filter out empty parts
    .join('&');
}
